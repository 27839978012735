import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
// import isEmpty from 'lodash/isEmpty'
import { Helmet } from 'react-helmet'

import Welcome from './Welcome'
import Introduction from './Introduction'
import WhereToBuy from './WhereToBuy'
import MenuSlider from './MenuSlider'
import NewsAndPromotionSlider from './NewsAndPromotionSlider'

const Home = () => {
  const appData = useSelector((state) => state.data)

  // if (isEmpty(appData.content)) return <Style />
  const { whereToBuy } = appData

  return (
    <Style>
      <Helmet>
        <title>Benas</title>
        <meta name='title' content='Benas' />
        <meta name='description' content='' />
      </Helmet>
      {/* <iframe
        style={{ marginTop: "100px", position: "relative", zIndex: 1000 }}
        title="benas"
        src="https://flipbookpdf.net/web/site/b4a4a4ad6b458baa151215f0d838c546ce7ec633202210.pdf.html"
        width="100%"
        height="500px"
      /> */}
      {/* <iframe
        style={{ marginTop: "100px", position: "relative", zIndex: 1000 }}
        title="benas"
        src="https://heyzine.com/flip-book/21d784886f.html"
        width="100%"
        height="500px"
      />
      <iframe
        title="x"
        style={{ marginTop: "100px", width: "100%", height: "750px", zIndex: 1000 }}
        src="https://online.anyflip.com/lqule/akdk/index.html"
        seamless="seamless"
        scrolling="no"
        frameBorder="0"
        allowTransparency="true"
        allowFullscreen="true" >
      </iframe> */}
      <Welcome />
      <Introduction />
      <MenuSlider />
      <NewsAndPromotionSlider isHome />
      <WhereToBuy items={whereToBuy} />
    </Style>
  )
}

const Style = styled('div')`
  min-height: 100vh;
`

export default Home
