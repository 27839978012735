import { Global, css } from '@emotion/react'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { setConfig } from 'react-hot-loader'
import { useEffect } from 'react'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'normalize.css'
import './style/grid-flex.css'
import './fonts/fonts.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'flag-icons/css/flag-icons.min.css'

import { history } from './utils'
import { getContent } from './actions/action'
import configureStore from './store/configureStore'
import Routes from './routes'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import Modal from './components/Modal'
import Translate from './components/Translate'
import Cookie from './components/Cookie'
import i18n from './i18n'

setConfig({
  pureSFC: true,
  reloadHooks: true
})

const store = configureStore(window.__INITIAL_STATE__)

const globalStyles = css`
  html,
  body {
    font-family: 'aller_bold', 'Kanit', sans-serif;
    background: #fff;
    color: #fff;
    overflow-x: hidden;
    width: 100%;
    position: relative;
    min-height: 100vh;

    &.fixed {
      /* overflow: hidden !important; */
    }
  }

  .toastify {
    font-family: 'aller_bold';
    &.is-success,
    &.is-danger {
      color: #000;
    }
  }

  .react-parallax > img {
    max-width: inherit;
  }

  .slick-slide,
  .slick-slide * {
    outline: none !important;
  }

  .animate {
    opacity: 0;
  }

  .disable-scroll {
    overflow: hidden !important;
  }

  .overlay {
    display: none;
    visibility: hidden;
    position: fixed;
    z-index: 95;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
    background-color: #000;
  }

  .slick-dots {
    li {
      button:before {
        color: #fff;
        opacity: 1;
        font-size: 11px;
      }

      &.slick-active button:before {
        color: #482c89;

        &:hover {
          color: #482c89;
        }
      }
    }
  }
`

function Root() {
  useEffect(() => {
    store.dispatch(getContent({ lang: i18n.language }))
  }, [])

  return (
    <Provider store={store}>
      <Global styles={globalStyles} />
      <Router history={history}>
        <Navigation />
        <Routes />
        <Footer />
      </Router>
      <Modal name='error' width='600px'>
        <Translate en='Sorry, Please try again later' th='ขออภัย กรุณาลองใหม่ภายหลัง' />
      </Modal>
      <Cookie />
    </Provider>
  )
}

if (module.hot) {
  module.hot.dispose(() => (window.__INITIAL_STATE__ = store.getState()))
}

export default process.env.NODE_ENV === 'development' ? hot(Root) : Root
